<!--
 * @Date: 2021-03-29 13:37:55
 * @LastEditors: frank
 * @LastEditTime: 2021-05-15 13:43:49
 * @FilePath: /shop_frontend/src/views/pageDecoration/previewComponent.vue
 * @Description: Description
-->
<template>
  <section class="previewComponent">
    <div
      v-if="typeStr === 'sign_module'"
      class="sign_module"
      :style="{ backgroundImage: `url('${info.picture_url}')` }"
    >
      <div>
        <p>{{ info.name }}</p>
        <p>{{ info.notice }}</p>
      </div>
    </div>
    <div
      v-if="typeStr === 'text_module'"
      class="text_module"
      :style="{
        color: info.font_color,
        backgroundColor: info.background_color,
        textAlign: { 0: 'left', 1: 'center', 2: 'right' }[info.position],
      }"
    >
      {{ info.content }}
    </div>
    <div
      v-if="typeStr === 'card_module'"
      class="card_module"
      :style="{
        backgroundImage: `url('${info.card_background_url}')`,
        color: info.font_color,
      }"
    >
      <div class="card_module_desc">
        <!-- <div>{{ info.card_name }}</div> -->
        <!-- <div>{{ info.card_level }}</div> -->
      </div>
      <div class="card_module_bottom">
        <p>更多权益领取后查看</p>
        <span>我要领卡</span>
      </div>
    </div>
    <div
      v-if="typeStr === 'nav_module'"
      class="nav_module"
      :style="{
        color: info.font_color,
        backgroundColor: info.background_color,
      }"
    >
      <div
        v-for="(el, index) in info.menu_items"
        :key="index"
        :style="{
          color: info.font_color,
          backgroundColor: info.background_color,
        }"
      >
        <img :src="el.icon_url" alt="" />
        <span>{{ el.content }}</span>
      </div>
    </div>
    <div v-show="typeStr === 'carousel_module'" class="carousel_module">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in (info && info.panel_items) || []"
            :key="index"
          >
            <img
              :src="item.picture_url"
              alt=""
              style="width: 100%; height: 100%; display: inline-block"
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div v-if="typeStr === 'graphic_module'" class="graphic_module">
      <div class="graphic_module_box" v-if="info.style === 0">
        <img :src="info.picture_url" alt="" />
        <p>{{ info.content || "" }}</p>
      </div>
      <div class="graphic_module_box" v-if="info.style === 1">
        <p>{{ info.content || "" }}</p>
        <img :src="info.picture_url" alt="" />
      </div>
    </div>
    <div v-if="typeStr === 'goods_module'" class="goods_module">
      <div class="custom__search">
        <a-input ref="userNameInput">
          <a-icon slot="prefix" type="search" />

          <a-icon type="picture" slot="suffix" />
        </a-input>
      </div>
      <ul class="custom__cate">
        <li>销量</li>
        <li>价格</li>
        <li>筛选</li>
      </ul>
      <ul class="goods__container">
        <li class="goods__box" v-for="(el, index) in goodsList" :key="index">
          <img
            :src="
              el.picture_items &&
              el.picture_items[0] &&
              el.picture_items[0].image
            "
            alt=""
          />
          <div class="goods__title">{{ el.name }}</div>
          <div class="goods__price">￥{{ el.min_price || 0 }}</div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Swiper from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";

import Swiper from "swiper/js/swiper.min";
import "swiper/css/swiper.min.css";

export default {
  name: "previewComponent",
  data() {
    return {
      goodsList: [],
    };
  },
  props: {
    typeStr: {
      type: String,
    },
    info: {
      type: Object,
    },
  },
  components: {
    // Swiper,
    // SwiperSlide,
  },
  computed: {},
  watch: {
    info: {
      handler: function () {
        console.log(this.info, "info");
        this.$nextTick(() => {
          if (this.typeStr === "carousel_module") {
            console.log(this.info, "info----");
            this.initSwiper();
          }
        });
      },
      immediate: true,
    },
  },
  created() {
    console.log(this.typeStr);

    if (this.typeStr === "goods_module") {
      this.getGoods();
    }
  },
  mounted() {},
  methods: {
    getGoods() {
      this.$axios.get("/goods/").then((res) => {
        console.log(res);
        this.goodsList = res.results || [];
      });
    },
    initSwiper() {
      var mySwiper = new Swiper(".swiper-container", {
        loop: false, // 循环模式选项
        pagination: {
          el: ".swiper-pagination",
        },
        observer: true,
        observeParents: true,
        autoplay: true, //可选选项，自动滑动
        // autoplay: {
        //   disableOnInteraction: false,
        // },
      });
    },
  },
};
</script>

<style scoped lang="less">
.custom__cate {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  // margin-bottom: 0;
}
.custom__search {
  padding: 10px;
  margin-bottom: 10px;
}
.goods__container {
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  border-top: 1px solid #ddd;
}
.goods__box {
  width: 50%;
  padding-right: 2%;
  img {
    display: block;
    width: 100%;
    border: 1px solid #ddd;
    // min-height: 150px;
    height: 180px;
    object-fit: contain;
    // height: 200px;
    // border: 1px solid #ddd;
  }
  .goods__title {
    font-size: 14px;
    padding: 5px;
    height: 46px;
  }
  .goods__price {
    font-size: 14px;
    padding: 5px;
    color: #d83d90;
  }
}
.previewComponent {
  > div {
    margin: 10px 0;
  }
  .sign_module {
    height: 210px;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: center;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .text_module {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 20px;
  }
  .card_module {
    height: 140px;
    margin-left: 10px;
    margin-right: 10px;
    // background-color: #dddddd;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    .card_module_desc {
      margin: 30px;
    }
    .card_module_bottom {
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: flex;
      align-items: center;
      > p {
        margin: 0;
      }
      > span {
        margin-left: 10px;
        display: inline-block;
        padding: 2px 5px;
        border: 1px solid #ffffff;
        font-size: 12px;
        border-radius: 10px;
      }
    }
  }
  .nav_module {
    display: flex;
    flex-flow: row wrap;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 25%;
      padding: 10px;
      img {
        display: inline-block;
        width: 70px;
        height: 70px;
        border-radius: 100%;
      }
    }
  }
  .carousel_module {
    width: 100%;
    // height: 140px;
    height: 210px;
    // background-color: #dddddd;
    .swiper-container {
      height: 100%;
    }
    .swiper-wrapper,
    .swiper-slide {
      height: 100%;
    }
  }
}
.graphic_module_box {
  display: flex;
  flex-flow: row nowrap;
  min-height: 180px;
  padding: 0 10px;
  > img {
    width: 180px;
    height: 180px;
    display: inline-block;
  }
  > p {
    margin: 0;
    flex: 1;
    padding: 4px;
    text-align: left;
  }
}
</style>
<style>
/* .swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #00ff33;
} */
</style>
