var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"previewComponent"},[(_vm.typeStr === 'sign_module')?_c('div',{staticClass:"sign_module",style:({ backgroundImage: ("url('" + (_vm.info.picture_url) + "')") })},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.info.name))]),_c('p',[_vm._v(_vm._s(_vm.info.notice))])])]):_vm._e(),(_vm.typeStr === 'text_module')?_c('div',{staticClass:"text_module",style:({
      color: _vm.info.font_color,
      backgroundColor: _vm.info.background_color,
      textAlign: { 0: 'left', 1: 'center', 2: 'right' }[_vm.info.position],
    })},[_vm._v(" "+_vm._s(_vm.info.content)+" ")]):_vm._e(),(_vm.typeStr === 'card_module')?_c('div',{staticClass:"card_module",style:({
      backgroundImage: ("url('" + (_vm.info.card_background_url) + "')"),
      color: _vm.info.font_color,
    })},[_c('div',{staticClass:"card_module_desc"}),_vm._m(0)]):_vm._e(),(_vm.typeStr === 'nav_module')?_c('div',{staticClass:"nav_module",style:({
      color: _vm.info.font_color,
      backgroundColor: _vm.info.background_color,
    })},_vm._l((_vm.info.menu_items),function(el,index){return _c('div',{key:index,style:({
        color: _vm.info.font_color,
        backgroundColor: _vm.info.background_color,
      })},[_c('img',{attrs:{"src":el.icon_url,"alt":""}}),_c('span',[_vm._v(_vm._s(el.content))])])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.typeStr === 'carousel_module'),expression:"typeStr === 'carousel_module'"}],staticClass:"carousel_module"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l(((_vm.info && _vm.info.panel_items) || []),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{staticStyle:{"width":"100%","height":"100%","display":"inline-block"},attrs:{"src":item.picture_url,"alt":""}})])}),0),_c('div',{staticClass:"swiper-pagination"})])]),(_vm.typeStr === 'graphic_module')?_c('div',{staticClass:"graphic_module"},[(_vm.info.style === 0)?_c('div',{staticClass:"graphic_module_box"},[_c('img',{attrs:{"src":_vm.info.picture_url,"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.info.content || ""))])]):_vm._e(),(_vm.info.style === 1)?_c('div',{staticClass:"graphic_module_box"},[_c('p',[_vm._v(_vm._s(_vm.info.content || ""))]),_c('img',{attrs:{"src":_vm.info.picture_url,"alt":""}})]):_vm._e()]):_vm._e(),(_vm.typeStr === 'goods_module')?_c('div',{staticClass:"goods_module"},[_c('div',{staticClass:"custom__search"},[_c('a-input',{ref:"userNameInput"},[_c('a-icon',{attrs:{"slot":"prefix","type":"search"},slot:"prefix"}),_c('a-icon',{attrs:{"slot":"suffix","type":"picture"},slot:"suffix"})],1)],1),_vm._m(1),_c('ul',{staticClass:"goods__container"},_vm._l((_vm.goodsList),function(el,index){return _c('li',{key:index,staticClass:"goods__box"},[_c('img',{attrs:{"src":el.picture_items &&
            el.picture_items[0] &&
            el.picture_items[0].image,"alt":""}}),_c('div',{staticClass:"goods__title"},[_vm._v(_vm._s(el.name))]),_c('div',{staticClass:"goods__price"},[_vm._v("￥"+_vm._s(el.min_price || 0))])])}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card_module_bottom"},[_c('p',[_vm._v("更多权益领取后查看")]),_c('span',[_vm._v("我要领卡")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"custom__cate"},[_c('li',[_vm._v("销量")]),_c('li',[_vm._v("价格")]),_c('li',[_vm._v("筛选")])])}]

export { render, staticRenderFns }